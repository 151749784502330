import {createContext, FC, PropsWithChildren, useCallback, useContext, useMemo, useState} from 'react'
import {useUserContext} from './UserContext'
import {FileUpload} from '../types/File'
import {deleteFile, getUploadFileURL, putFile} from '../service/persistenceService'
import {useUser} from '@clerk/clerk-react'
import {areFileUploadsMatchChanged, areFileUploadsMatch, createFileUpload, hasId} from '../utils/fileUtils'
import {put, remove} from '../utils/genericUtils'
import { AIModelID } from '../types/AiModel'

type FilesContextValue = {
    files: FileUpload[]
    /** Remove files from context without removing them from database
     * @param filterCondition If present, clear fileUploads based on filter condition. If not, clear all */
    clearFiles: (filterCondition?: (fileUpload: FileUpload) => boolean) => void
    removeFile: (fileUpload: FileUpload) => void
    retryUpload: (fileUPload: FileUpload) => void
    uploadFile: (file: File, modelId?: AIModelID, conversationId?: string, messageId?: string) => void
}

const DEFAULT_FILES_CONTEXT_VALUE: FilesContextValue = {
    files: [],
    clearFiles: () => {},
    removeFile: () => {},
    retryUpload: () => {},
    uploadFile: () => {},
}

const FilesContext = createContext<FilesContextValue>(DEFAULT_FILES_CONTEXT_VALUE)

export const useFilesContext = () => useContext(FilesContext)

export const FilesContextProvider: FC<PropsWithChildren> = ({
    children
}) => {
    const {token} = useUserContext()
    const {user} = useUser()
    const [fileUploads, setFileUploads] = useState<FileUpload[]>([])
    const userId = (user?.id ?? user?.externalId)!

    const upload = useCallback((fileUpload: FileUpload) => {
        const loadingFileUpload: FileUpload = { ...fileUpload, state: 'loading' }
        const {metadata: {conversationId, messageId, id: fileId}, file} = fileUpload
        setFileUploads(put(loadingFileUpload, areFileUploadsMatch, areFileUploadsMatchChanged))
        getUploadFileURL(token, fileId, conversationId, messageId)
            .then(({uploadURL}) => putFile(uploadURL, file))
            .then(() => {
                const completedFileUpload: FileUpload = { ...fileUpload, state: 'completed' }
                setFileUploads(put(completedFileUpload, areFileUploadsMatch, areFileUploadsMatchChanged))
            })
            .catch(() => {
                const failedFiledUpload: FileUpload = { ...fileUpload, state: 'error' }
                setFileUploads(put(failedFiledUpload, areFileUploadsMatch, areFileUploadsMatchChanged))
            })
    }, [token])

    const clearFiles = useCallback((filterCondition?: (fileUpload: FileUpload) => boolean) => {
        setFileUploads(prev => filterCondition ? prev.filter(filterCondition) : [])
    }, [])
    
    const uploadFile = useCallback((file: File, modelId?: AIModelID, conversationId?: string, messageId?: string) => {
        const fileUpload = createFileUpload(file, modelId, userId, conversationId, messageId)
        setFileUploads(put(fileUpload, areFileUploadsMatch, areFileUploadsMatchChanged))
        upload(fileUpload)
    }, [upload, userId])

    const retryUpload = useCallback((fileUpload: FileUpload) => {
        upload(fileUpload)
    }, [upload])

    const removeFile = useCallback((fileUpload: FileUpload) => {
        const { id } = fileUpload.metadata
        deleteFile(token, id)
        setFileUploads(remove(hasId(id)))
    }, [token])

    const value: FilesContextValue = useMemo(() => ({
        files: fileUploads,
        clearFiles,
        uploadFile,
        retryUpload,
        removeFile,
    }), [fileUploads, clearFiles, removeFile, retryUpload, uploadFile])

    return (
        <FilesContext.Provider value={value}>
            {children}
        </FilesContext.Provider>
    )
}