import {createRoot} from 'react-dom/client'
import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import './index.css'
import {AppWrapper} from './AppWrapper'
import {IntegrationFeature} from './routes/IntegrationFeature/IntegrationFeature'
import {PromptsList} from './routes/promptsList/PromptsList'
import {PromptDetail} from './routes/promptDetail/PromptDetail'
import {LoadAnalytics} from './service/SegmentService'
import {AiChat} from './components/aiChat/AiChat'
import {AdminWrapper} from './components/admin/AdminWrapper'
import {HomeWrapper} from './components/home/HomeWrapper'
import {NoModelsEnabledError} from './components/error/NoModelsEnabledError'
import {AccountCreationWrapper} from './components/accountCreation/AccountCreationWrapper'
import {AccountCreationStep} from './components/accountCreation/AccountCreationStep'
import {MyPrompts} from './components/myPrompts/MyPrompts'
import {InspirationPrompts} from './components/inspirationPrompts/InspirationPrompts'
import {SavedChat} from './components/chat/SavedChat'
import {Trial} from './components/trial/Trial'
import {PricingTable} from './components/pricingTable/PricingTable'
import {GroupStatistics} from './components/admin/groupStatistics/GroupStatistics'
import {AdminOverview} from './components/admin/AdminOverview'
import {AuditingLogsTable} from './components/admin/audit/AuditingLogsTable'
import {AppPrivateWrapper} from './AppPrivateWrapper'
import {AuditingLogDetail} from './components/admin/audit/AuditingLogDetail'
import {GlobalSettings} from './components/admin/configuration/settings/GlobalSettings'
import {Groups} from './components/admin/configuration/groups/Groups'
import {UsersList} from './components/admin/configuration/users/UsersList'
import {AiModelsControl} from './components/admin/configuration/aiModels/AiModelsControl'
import {Safeguard} from './components/admin/configuration/safeguard/Safeguard'
import {BudgetControl} from './components/admin/configuration/budget/BudgetControl'
import {ChatCards} from './components/chat/ChatCards'
import {InitSentry} from './service/SentryService'
import {RouterErrorPage} from './error/RouterErrorPage'

if (process.env.REACT_APP_SENTRY_ENABLED === 'true') {
	InitSentry()
}

if (process.env.REACT_APP_SEGMENT_ENABLED === 'true') {
	LoadAnalytics()
}

const router = createBrowserRouter([
	{
		path: '/',
		id: 'root',
		element: <AppWrapper/>,
		errorElement: <RouterErrorPage/>,
		children: [
			{
				element: <AppPrivateWrapper/>,
				children: [
					{
						element: <HomeWrapper/>,
						children: [
							{path: '', element: <PromptsList/>},
							{path: 'integration', element: <IntegrationFeature/>},
							{path: 'prompt/:promptId/copy', element: <PromptDetail copy={true}/>},
							{path: 'prompt/:promptId/:models', element: <PromptDetail/>},
							{path: 'prompt/:promptId/execute/:models', element: <PromptDetail runAiPrompt={true}/>},
							{path: 'aiChat/optimized', element: <AiChat isPromptOptimized={true}/>},
							{path: 'aiChat', element: <AiChat/>},
							{path: 'aiChat/:chatId', element: <SavedChat/>},
							{path: 'noModelsEnabled', element: <NoModelsEnabledError/>},
							{path: 'inspirationPrompts', element: <InspirationPrompts/>},
							{path: 'chats', element: <ChatCards/>},
							{path: 'myPrompts', element: <MyPrompts/>},
						]
					}, {
						path: 'admin',
						element: <AdminWrapper/>,
						children: [
							{path: '', element: <AdminOverview/>},
							{path: 'logs', element: <AuditingLogsTable/>},
							{path: 'group/:groupId', element: <GroupStatistics/>},
							{path: 'logs/:logId', element: <AuditingLogDetail/>},
							{path: 'configuration', element: <GlobalSettings/>},
							{path: 'configuration/models', element: <AiModelsControl/>},
							{path: 'configuration/groups', element: <Groups/>},
							{path: 'configuration/groups/:groupId', element: <Groups/>},
							{path: 'configuration/budget', element: <BudgetControl/>},
							{path: 'configuration/users', element: <UsersList/>},
							{path: 'configuration/safeguard', element: <Safeguard/>}
						]
					}, {
						element: <AccountCreationWrapper/>,
						children: [
							{path: 'create-account-basic-information', element: <AccountCreationStep index={0}/>,},
							{path: 'create-account-assign-admins', element: <AccountCreationStep index={1}/>},
							{path: 'create-account-connect-ai', element: <AccountCreationStep index={2}/>},
						]
					},
					{
						path: 'pricing',
						element: <PricingTable/>
					}
				]
			},
			{
				path: 'trial',
				element: <Trial/>,
			}
		]
	}
])

createRoot(document.getElementById('root') as HTMLElement).render(
	<RouterProvider router={router} fallbackElement={<p>Loading Narus app....</p>}/>
)