import {ChangeEvent, FC, useCallback} from 'react'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import {IconButton} from '@mui/material'
import {useFilesContext} from '../../context/FilesContext'
import {AIModelID} from '../../types/AiModel'

type Props = {
    modelId?: AIModelID
    conversationId?: string
    messageId?: string
}

export const FileUploadIconButton: FC<Props> = ({
    modelId,
    conversationId,
    messageId
}) => {
    const { uploadFile } = useFilesContext()

	const handleInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files
		if (files) {
            Array
                .from(files)
                .forEach(file => uploadFile(file, modelId, conversationId, messageId))
		}
	}, [modelId, conversationId, messageId, uploadFile])

    return (
        <IconButton component='label'>
            <AttachFileIcon/>
			<input type='file' accept='application/pdf' multiple hidden onChange={handleInputChange}/>
        </IconButton>
    )
}