import {useUserContext} from '../context/UserContext'
import {useCallback, useEffect, useState} from 'react'
import {UserInfo} from '../types/UserInfo'
import {getUsersInfo, updateUsersInfo} from '../service/persistenceService'
import {useFeedbackContext} from '../context/FeedbackContext'
import {captureException} from '@sentry/core'

type UseUsersAccessValue = {
	loading: boolean
	usersInfo: UserInfo[]
	updateUserInfo: (userInfo: UserInfo) => Promise<void>
}

export const useUsersAccess = (): UseUsersAccessValue => {
	const {token} = useUserContext()
	const {showFeedback} = useFeedbackContext()

	const [loading, setLoading] = useState<boolean>(true)
	const [usersInfo, setUsersInfo] = useState<UserInfo[]>([])

	const getAllUsersInfo = useCallback(() => {
		setLoading(true)
		getUsersInfo(token).then(usersInfo => {
			setUsersInfo(usersInfo.sort((userInfoA, userInfoB) => userInfoA.userFullName > userInfoB.userFullName ? 1 : -1))
			setLoading(false)
		}).catch(error => {
			captureException(error)
			showFeedback('Error', error.cause === 403 ?
				'It seems like you don’t have permissions to access this page. Contact your admin to know more.' :
				'Something went wrong to load data. Try again reloading the page', 'error')
			setUsersInfo([])
		}).finally(() => setLoading(false))

	}, [token, showFeedback])

	const updateUserInfo = useCallback(async (userInfo: UserInfo) => {
		setLoading(true)
		try {
			await updateUsersInfo(token, userInfo)
			setUsersInfo(previousValues => {
				const userInfoIndex = previousValues.findIndex(previousUserInfo => previousUserInfo.userId === userInfo.userId)
				if (userInfoIndex < 0) return previousValues
				const previousValuesUpdated = [...previousValues]
				previousValuesUpdated[userInfoIndex] = userInfo
				return previousValuesUpdated
			})
			showFeedback('Success', 'User info has been updated', 'success')
			setLoading(false)
		} catch (error: any) {
			captureException(error)
			showFeedback('Error', error.cause === 403 ?
				'It seems like you don’t have permissions to access this page. Contact your admin to know more.' :
				'Something went wrong to update the user data. Try again reloading the page', 'error')
		} finally {
			setLoading(false)
		}
	}, [showFeedback, token])

	useEffect(() => {
		getAllUsersInfo()
	}, [getAllUsersInfo])

	return {
		loading,
		usersInfo,
		updateUserInfo
	}
}