import {removePrompt} from '../service/persistenceService'
import {useCallback} from 'react'
import {usePromptsContext} from '../context/PromptsContext'
import { TrackActionEvent, getHomeTabForAnalytics } from '../service/SegmentService'
import { useUser } from '@clerk/clerk-react'
import { useUserContext } from '../context/UserContext'
import {captureException} from '@sentry/core'

export type UseDeletionPromptValue = {
    deletePrompt: (promptId: string) => Promise<void>
}

export const usePromptDeletion = (userToken: string): UseDeletionPromptValue => {
    const {selectedTab} = useUserContext()
    const {user} = useUser()
    const {setPrompts, setFavourites, setRecentlyUsedPrompts, setUserPrompts} = usePromptsContext()

    const deletePrompt = useCallback(async (promptId: string) => {
        return removePrompt(promptId, userToken).then(() => {
            TrackActionEvent('Prompt', user?.externalId ?? user?.id, {
                action: 'delete',
                origin: getHomeTabForAnalytics(selectedTab),
                prompt_id: promptId
            })
            setPrompts(previousPrompts => previousPrompts.filter(prompt => prompt.id !== promptId))
            setFavourites(previousFavourites => previousFavourites.filter(favourite => favourite.id !== promptId))
            setRecentlyUsedPrompts(previousRecentlyUsed => previousRecentlyUsed.filter(recentlyUsed => recentlyUsed.id !== promptId))
            setUserPrompts(prev => prev.filter(({ id }) => id !== promptId))
        }).catch(error => {
            captureException(error)
            return undefined
        })
    }, [userToken, user?.id, user?.externalId, selectedTab, setPrompts, setFavourites, setRecentlyUsedPrompts, setUserPrompts])

    return {deletePrompt}
}