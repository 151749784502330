import {Box, Divider, Grid, Tooltip, Typography} from '@mui/material'
import {useStatisticsContext} from '../../../context/StatisticsContext'
import FunctionsIcon from '@mui/icons-material/Functions'
import {NoDataAvailable} from './NoDataAvailable'
import Spinner from '../../spinner/Spinner'
import {AiModelIcon} from '../../icons/AiModelIcon'
import {roundTwoDecimals} from '../../../helpers/NumberHelpers'
import {useBudgetsContext} from '../../../context/BudgetContext'
import {BudgetStatistics} from './BudgetStatistics'
import './CostPerAIModel.scss'
import { BUDGET_CHART_DISPLAYED_MODELS } from '../../../constants/BudgetConstants'

export const CostPerAIModel = () => {
	const {usageStatistics, loading, topModelsByCost} = useStatisticsContext()
	const {overallBudget} = useBudgetsContext()
	const noBudgetClassNameSuffix = overallBudget ? '' : 'noBudget'
	
	return <Box className={`expenditureContainer ${noBudgetClassNameSuffix}`}>
		<Box className='expenditureRow'>
			<Typography className='expenditureTitle' variant='h3'>Expenditure</Typography>
		</Box>
		{loading ? <Grid item xs={12} className='spinnerContainer'><Spinner/></Grid> : <></>}
		{!loading && !usageStatistics?.modelCostStatistics ? <NoDataAvailable/> : <></>}
		{!loading && usageStatistics?.modelCostStatistics ? <>
			<Box className={`expenditureRow ${noBudgetClassNameSuffix}`}>
				<Box className='totalCostContainer'>
					<Box className={`totalCostIconContainer`}>
						<FunctionsIcon className='totalCostIcon'/>
					</Box>
					<Box>
						<Typography variant='subtitle2' className='totalCostTitle'>Total spend</Typography>
						<Typography variant='h3' className='totalCostAmount'>${roundTwoDecimals(usageStatistics.modelCostStatistics.totalCostAllModels)}</Typography>
					</Box>
				</Box>
				<Divider orientation={overallBudget ? 'vertical' : 'horizontal'} flexItem/>
				<Box className='breakdownContainer'>
					<Typography className='breakdownTitle' variant='h4'>Top {BUDGET_CHART_DISPLAYED_MODELS} highest spending AIs</Typography>
					<Grid container rowSpacing={1} className='breakdownTable'>
						{topModelsByCost.map(({modelId, modelName, cost}, index) =>
							<Grid item xs={6} md={4} lg={4} key={`${modelId}-${index}`}>
								<Box className='breakdownModelCost'>
									<Box><AiModelIcon modelId={modelId}/></Box>
									<Tooltip title={modelName}><Typography variant='subtitle2' className='breakdownModelName'>{modelName}</Typography></Tooltip>
								</Box>
								<Typography className='breakdownAmount' variant='h5'>${roundTwoDecimals(cost)}</Typography>
							</Grid>
						)}
					</Grid>
				</Box>
			</Box>
            {overallBudget ? <>
				<Divider className='expenditureSeparator'/>
				<Box className='expenditureRow'>
					<BudgetStatistics budget={overallBudget}/>
				</Box>
			</> : <></>}
            
        </> : <></>}
	</Box>
}