import {StatisticsContextProvider} from '../../context/StatisticsContext'
import {UsersAccessContextProvider} from '../../context/UsersAccessContext'
import {ToggleDrawerContextProvider} from '../../context/ToggleDrawerContext'
import {AiModelsContextProvider} from '../../context/AIModelsContext'
import {AuditingLogsContextProvider} from '../../context/AuditingLogsContext'
import {OrganizationContextProvider} from '../../context/OrganizationContext'
import {AdminEmailsContextProvider} from '../../context/AdminEmailsContext'
import {UserInfoContextProvider} from '../../context/UserInfoContext'
import {WarningsContextProvider} from '../../context/WarningsContext'
import {GroupsContextProvider} from '../../context/GroupsContext'
import {AdminNavigationContextProvider} from '../../context/AdminNavigationContextProvider'
import {Admin} from './Admin'
import {TrialBoundary} from '../trial/TrialBoundary'
import {UserOrganizationContextProvider} from '../../context/UserOrganizationContext'
import {FeedbackContextProvider} from '../../context/FeedbackContext'
import {StripeSubscriptionsContextProvider} from '../../context/StripeSubscriptionsContext'
import {DataGridContextProvider} from '../../context/DataGridContext'
import {BudgetsContextProvider} from '../../context/BudgetContext'
import {AppGridWrapper} from '../appGridWrapper/AppGridWrapper'
import {AppMenuWrapper} from '../appMenu/AppMenuWrapper'
import {AppNavigationContextProvider} from '../../context/AppNavigationContext'
import {Outlet} from 'react-router-dom'

export const AdminWrapper = () => {
    return (
        <UserOrganizationContextProvider>
            <FeedbackContextProvider>
                <UserInfoContextProvider>
                    <UsersAccessContextProvider>
                        <StripeSubscriptionsContextProvider>
                            <ToggleDrawerContextProvider>
                                <AiModelsContextProvider>
                                    <StatisticsContextProvider>
                                        <AuditingLogsContextProvider>
                                            <OrganizationContextProvider>
                                                <AdminEmailsContextProvider>
                                                    <WarningsContextProvider>
                                                        <GroupsContextProvider>
                                                            <AdminNavigationContextProvider>
                                                                <DataGridContextProvider>
                                                                    <BudgetsContextProvider>
                                                                        <AppNavigationContextProvider>
                                                                            <AppMenuWrapper>
                                                                                <AppGridWrapper>
                                                                                    <TrialBoundary>
                                                                                        <Admin>
                                                                                            <Outlet/>
                                                                                        </Admin>
                                                                                    </TrialBoundary>
                                                                                </AppGridWrapper>
                                                                            </AppMenuWrapper>
                                                                        </AppNavigationContextProvider>
                                                                    </BudgetsContextProvider>
                                                                </DataGridContextProvider>
                                                            </AdminNavigationContextProvider>
                                                        </GroupsContextProvider>
                                                    </WarningsContextProvider>
                                                </AdminEmailsContextProvider>
                                            </OrganizationContextProvider>
                                        </AuditingLogsContextProvider>
                                    </StatisticsContextProvider>
                                </AiModelsContextProvider>
                            </ToggleDrawerContextProvider>
                        </StripeSubscriptionsContextProvider>
                    </UsersAccessContextProvider>
                </UserInfoContextProvider>
            </FeedbackContextProvider>
        </UserOrganizationContextProvider>
    )
}