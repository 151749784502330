import {Outlet} from 'react-router-dom'
import {AccountCreationContextProvider} from '../../context/AccountCreationContext'
import {UserOrganizationContextProvider} from '../../context/UserOrganizationContext'
import {TrialContextProvider} from '../../context/TrialContext'

export const AccountCreationWrapper = () => (
    <UserOrganizationContextProvider>
        <TrialContextProvider>
            <AccountCreationContextProvider>
                <Outlet/>
            </AccountCreationContextProvider>
        </TrialContextProvider>
    </UserOrganizationContextProvider>
)
