import {Badge, Box, Button, IconButton, TextField, Typography} from '@mui/material'
import {AiModelSelector} from '../aiModelSelector/AiModelSelector'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import {Sticky} from '../Sticky/Sticky'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import './PromptSearchBarHomeDisabled.scss'

export const PromptSearchBarHomeDisabled = () => {

    return <>
        <Sticky position='top' unstuckClasses='' stuckClasses='searchBarContainerStuck'>
            <Box className='promptInputContainer disabled'>
                <AiModelSelector disabled={true}/>
                <TextField
                    disabled
                    className='searchBar'
                    placeholder='Write your prompt here'
                />
                <Box className='promptSettingsRow'>
                    <svg width={0} height={0}>
                        <linearGradient x1="11.9951" y1="-15.2656" x2="12.2134"
                                        y2="22.905" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCCCCC"/>
                        </linearGradient>
                    </svg>
                    <Button disabled className='promptOptimizationButton'>
                        <Badge className='optimizationBadge'>
                            <AutoFixHighIcon className='promptOptimizationIcon'/>
                        </Badge>
                        <Typography className='optimizationText disabled'>Prompt optimization</Typography>
                    </Button>
                    <Box className='fileUploadButtonContainer'>
                        <IconButton disabled>
                            <AttachFileIcon/>
                        </IconButton>
                    </Box>
                    <Button variant='contained' className='sendButton' disabled>Send to AI</Button>
                </Box>
            </Box>
        </Sticky>
    </>
}