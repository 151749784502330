import React, {ChangeEvent, useState} from 'react'
import {Box, Button, Stack, Typography} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import TextField from '@mui/material/TextField'
import {useToggleDrawerContext} from '../../context/ToggleDrawerContext'
import CreatableSelect from 'react-select/creatable'
import ChipsLabelTeam from '../chipsLabelTeam/ChipsLabelTeam'
import {usePromptCreationContext} from '../../context/PromptCreationContext'
import './SaveChatHistory.scss'
import {SingleValue} from 'react-select'
import {SelectOption} from '../../types/SelectOption'
import {useFeedbackContext} from '../../context/FeedbackContext'
import {SaveChatProps} from '../../types/SaveChatProps'
import {useChatsContext} from '../../context/ChatsContext'
import {usePromptsContext} from '../../context/PromptsContext'
import {TrackActionEvent} from '../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import {useChatMessagesContext} from '../../context/ChatMessagesContext'
import {captureException} from '@sentry/core'

const SaveChatHistory = ({modelId, tempPromptId}: SaveChatProps) => {

    const {toggleDrawer, handleCloseSaveChatDrawer} = useToggleDrawerContext()
    const {
        saveChat, editChat,
        setChatValueHandler,
        setChatName,
        setChatLabels,
        setChatDescription,
        chatLabels,
        chatName,
        chatDescription,
        getChatTemplate
    } = useChatsContext()
    const {chatId} = useChatMessagesContext()
    const {user} = useUser()

    const {
        setUnsavedChanges,
        setValueHandler,
        promptChats
    } = usePromptCreationContext()
    const {showFeedback} = useFeedbackContext()
    const {labels, setLabels} = usePromptsContext()
    const [errorChatName, setErrorChatName] = useState<boolean>(false)

    const newChatMessages = promptChats.find(chats => chats.modelId === modelId)?.messages ?? []

    const saveChatHandler = () => {
        saveChat({...getChatTemplate(newChatMessages, modelId)}, tempPromptId)
            .then((storedChat) => {
                showFeedback('Chat saved!', 'The chat was saved!', 'success')
                TrackActionEvent('Save chat conversation', user?.externalId ?? user?.id, {
                    action: 'save',
                    chat_id: storedChat?.chatId,
                    labels: storedChat?.labels
                })
            })
            .catch(error => {
                captureException(error)
                showFeedback('Error', 'The chat couldn\'t be saved, please try again.', 'error')
            })
            .finally(() => setUnsavedChanges(false))
    }

    const updateChatHandler = () => {
        editChat({...getChatTemplate(newChatMessages, modelId), chatId})
            .then((updatedChat) => {
                showFeedback('Chat updated!', 'The chat was updated!', 'success')
                TrackActionEvent('Save chat conversation', user?.externalId ?? user?.id, {
                    action: 'update',
                    chat_id: updatedChat?.chatId,
                    labels: updatedChat?.labels
                })
            })
            .catch(error => {
                captureException(error)
                showFeedback('Error', 'The chat couldn\'t be updated, please try again.', 'error')
            })
            .finally(() => setUnsavedChanges(false))
    }

    const saveChatConversationHandler = async () => {
        if (!chatName) {
            setErrorChatName(true)
            return
        }
        chatId ? await updateChatHandler() : await saveChatHandler()
        handleCloseSaveChatDrawer('Save chat conversation')
    }

    const updateChatNameHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setErrorChatName(false)
        setChatValueHandler(setChatName, event.target.value, 'chatName')
    }

    const categoriesHandler = (selectedLabel: SingleValue<SelectOption>) => {
        setChatValueHandler(setChatLabels, chatLabels.indexOf(selectedLabel!.value) === -1 ? [selectedLabel!.value, ...chatLabels] : chatLabels, 'categories')
        setValueHandler(setLabels, labels.indexOf(selectedLabel!) === -1 ? [selectedLabel!, ...labels] : labels, 'labelOptions')
    }

    return <Stack className='saveChatContainer'>
        <Box className='saveChatTitle'>
            <Typography variant='h4'>Save chat</Typography>
            <CloseIcon onClick={toggleDrawer(false, 'SAVE_CHAT_HISTORY_DRAWER')}
                       cursor='pointer'
                       className='closeIcon'/>
        </Box>
        <Box className='saveChatDescription'>
            <Typography variant='caption' className='saveChatDescriptionText'>Save your chat history. Give it a name and description so you can find it later.</Typography>
        </Box>
        <Box className='saveChatName'>
            <Typography variant='body2' className='fieldLabel'>Name *</Typography>
            <TextField
                required
                inputProps={{maxLength: 150}}
                className='textFieldInput textFieldChatName'
                onChange={updateChatNameHandler}
                placeholder='Market Analysis'
                fullWidth
                error={errorChatName}
                variant='outlined'
                value={chatName}
            />
        </Box>
        <Box className='saveChatDescription'>
            <Typography variant='body2' className='fieldLabel'>Description</Typography>
            <TextField
                inputProps={{maxLength: 600}}
                className='textFieldInput textFieldChatDescription'
                onChange={event => setChatValueHandler(setChatDescription, event.target.value, 'chatDescription')}
                placeholder='Overview of market trends over the last 3 months'
                multiline
                fullWidth
                variant='outlined'
                value={chatDescription}
            />
        </Box>
        <Typography className='fieldCategory' variant='body2'>Category</Typography>
        <CreatableSelect isSearchable options={labels}
                         value={null}
                         placeholder='Type or select a category'
                         className='creatableSelect categoryCreatableSelect'
                         onChange={categoriesHandler}/>
        <ChipsLabelTeam chips={chatLabels || []}/>
        <Box className='saveChatButtonContainer'>
            <Button className='saveChatButton' variant='contained' onClick={saveChatConversationHandler}>
                Save
            </Button>
        </Box>
    </Stack>
}

export default SaveChatHistory