import {FC, useCallback} from 'react'
import {Box, Chip} from '@mui/material'
import {FileUpload} from '../../types/File'
import {useFilesContext} from '../../context/FilesContext'
import DocumentIcon from '@mui/icons-material/DescriptionOutlined'
import './FileUploadChips.scss'

type Props = {
    fileUploads: FileUpload[]
    align?: 'left' | 'right'
}

export const FileUploadChips: FC<Props> = ({
    fileUploads,
    align
}) => {
    const suffix = align === 'right' ? 'fileUploadChips--right' : 'fileUploadChips--left'
    return <Box className={`fileUploadChips ${suffix}`}>
        { fileUploads.map(fileUpload => <FileUploadChip key={fileUpload.metadata.id} fileUpload={fileUpload}/>) }
    </Box>
}

type FileUploadChipProps = {
    fileUpload: FileUpload
}

const FileUploadChip: FC<FileUploadChipProps> = ({
    fileUpload
}) => {
    const { removeFile, retryUpload } = useFilesContext()
    const { state, metadata } = fileUpload
    const label = 
        state === 'error' ? 'upload error' :
        state === 'loading' ? 'uploading...' :
        metadata.name

    const clickable = state === 'error'

    const handleDelete = useCallback(() => {
        removeFile(fileUpload)
    }, [fileUpload, removeFile])

    const handleClick = useCallback(() => {
        if (clickable) {
            retryUpload(fileUpload)
        }
    },[clickable, fileUpload, retryUpload])

    return <Chip
        className='fileUploadChip' 
        icon={<DocumentIcon/>}
        color={state === 'error' ? 'error' : 'default'}
        label={label} 
        disabled={state === 'loading'}
        clickable={clickable}
        onClick={(handleClick)}
        onDelete={handleDelete} 
        />
}