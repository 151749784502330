import { createContext, FC, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppDialog, AppDialogProps } from '../components/appDialog/AppDialog'

export type DialogParams = Omit<AppDialogProps, 'children' | 'open'>

type AppNavigationContextValue = {
    navigate: (route: string, beforeNavigate?: () => void) => void
    addNavigationDialog: (dialog: DialogParams) => void
    removeNavigationDialog: (dialog: DialogParams) => void
}

const DEFAULT_APP_NAVIGATION_CONTEXT_VALUE: AppNavigationContextValue = {
    navigate: () => {},
    addNavigationDialog: () => {},
    removeNavigationDialog: () => {}
}

const AppNavigationContext = createContext<AppNavigationContextValue>(DEFAULT_APP_NAVIGATION_CONTEXT_VALUE)

export const useAppNavigationContext = () => useContext(AppNavigationContext)

export const AppNavigationContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const [dialogOpen, setDialogOpen] = useState(false)
    const [dialogs, setDialogs] = useState<DialogParams[]>([])
    const [navigateTarget, setNavigateTarget] = useState('')
    const navigate = useNavigate()

    const customNavigate = useCallback((route: string, beforeNavigate?: () => void) => {
        if (dialogs.length) {
            setNavigateTarget(route)
            setDialogOpen(true)
        } else {
            beforeNavigate?.()
            navigate(route)
        }
    },[dialogs, navigate])

    const lastDialog = dialogs.length ? dialogs[dialogs.length - 1] : undefined

    const composedDialogParams: DialogParams | undefined = useMemo(() => lastDialog ? {
        primaryText: lastDialog.primaryText,
        secondaryText: lastDialog.secondaryText,
        secondaryClassName: lastDialog.secondaryClassName,
        text: lastDialog.text,
        title: lastDialog.title,
        onClose: () => {
            lastDialog.onClose()
            setDialogOpen(false)
        },
        onPrimary: () => {
            lastDialog.onPrimary()
            setDialogOpen(false)
            navigate(navigateTarget)
        },
        onSecondary: lastDialog.onSecondary ? () => {
            lastDialog.onSecondary?.()
            setDialogOpen(false)
        } : undefined        
    } : undefined, [lastDialog, navigateTarget, navigate])

    const addNavigationDialog = useCallback((dialog: DialogParams) => {
        setDialogs(prev => [...prev, dialog])
    }, [])

    const removeNavigationDialog = useCallback((dialog: DialogParams) => {
        setDialogs(prev => prev.filter(item => item !== dialog))
    }, [])

    const value: AppNavigationContextValue = useMemo(() => ({
        navigate: customNavigate,
        addNavigationDialog,
        removeNavigationDialog,
    }),[customNavigate, addNavigationDialog, removeNavigationDialog])
    
    return <AppNavigationContext.Provider value={value}>
        { composedDialogParams ? <AppDialog {...composedDialogParams} open={dialogOpen} /> : <></> }
        {children}
    </AppNavigationContext.Provider>
}