import {v4 as uuidv4} from 'uuid'
import {FileId, FileUpload} from '../types/File'
import {hasProp, isEqual, not} from './genericUtils'
import {nowInSeconds} from './dateUtils'
import { AIModelID } from '../types/AiModel'

export const hasId = (id: FileId) => (fileUpload: FileUpload): boolean =>
    fileUpload.metadata.id === id

export const hasModelId = (modelId: AIModelID) => (fileUpload: FileUpload): boolean =>
    fileUpload.metadata.modelId === modelId

/** A fileUpload is considered binded once it has a conversationId/messageId */
export const isBinded = (fileUpload: FileUpload): boolean => 
    fileUpload.metadata.conversationId !== undefined && fileUpload.metadata.messageId !== undefined

/** Checks if a fileUpload is not binded but is associated to a model */
export const isModelUnbinded = (modelId: AIModelID) => (fileUpload: FileUpload): boolean =>
    hasModelId(modelId)(fileUpload) && !isBinded(fileUpload)

export const areFileUploadsMatch = (fileUploadA: FileUpload) => (fileUploadB: FileUpload): boolean =>
    fileUploadA.metadata.id === fileUploadB.metadata.id

export const areFileUploadsMatchChanged = (fileUploadA: FileUpload) => (fileUploadB: FileUpload): boolean =>
    areFileUploadsMatch(fileUploadA)(fileUploadB) && (fileUploadA.state !== fileUploadB.state || !isEqual(fileUploadA.metadata, fileUploadB.metadata))

export const createFileUpload = (file: File, modelId?: AIModelID, ownerId?: string, conversationId?: string, messageId?: string): FileUpload => ({
    file,
    state: 'idle',
    metadata: {
        id: `${uuidv4()}--${file.name ?? ''}`,
        name: file.name,
        timestamp: nowInSeconds(),
        modelId,
        ownerId,
        conversationId,
        messageId,
    }
})

export const getUnbindedUploadedFiles = (files: FileUpload[]): FileUpload[] => files
    .filter(not(isBinded))
    .filter(hasProp('state', 'completed'))