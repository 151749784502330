import React from 'react'
import './FavouriteIcon.scss'
import {useFeedbackContext} from '../../context/FeedbackContext'
import {useUserContext} from '../../context/UserContext'
import {usePromptFavourite} from "../../hooks/usePromptFavourite"
import StarIcon from '@mui/icons-material/Star'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import {Prompt} from '../../types/Prompt'
import {usePromptsContext} from '../../context/PromptsContext'
import {TrackActionEvent} from '../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import {captureException} from '@sentry/core'

interface FavouriteIconProps {
    prompt: Prompt
}

const FavouriteIcon = ({prompt}: FavouriteIconProps) => {

    const {token} = useUserContext()
    const {showFeedback} = useFeedbackContext()
    const {makeFavourite, removeFavourite} = usePromptFavourite(token)
    const {favourites} = usePromptsContext()
    const {user} = useUser()

    const favouriteId = favourites.find(favourite => favourite.id === prompt.id)?.favouriteId

    const markAsFavouritePromptHandler = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        makeFavourite(prompt)
            .then(() => showFeedback('Success', 'The prompt was marked as favourite!', 'success'))
            .catch(error => {
                captureException(error)
                showFeedback('Error', 'The action could not be performed, please try again.', 'error', 10)
            }).finally(() => {
                TrackActionEvent('Favourite', user?.externalId ?? user?.id, {
                action: 'mark',
                prompt_id: prompt.id
            })
        })
    }

    const removeFavouritePromptHandler = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        if (!favouriteId) {
            showFeedback('Error', 'The action could not be performed, please try again.', 'error', 10)
            return
        }

        removeFavourite(prompt, favouriteId)
            .then(() => showFeedback('Success', 'The prompt was removed from favourites!', 'success'))
            .catch(error => {
                captureException(error)
                showFeedback('Error', 'The action could not be performed, please try again.', 'error', 10)
            }).finally(() => {
            TrackActionEvent('Favourite', user?.externalId ?? user?.id, {
                action: 'unmark',
                prompt_id: prompt.id
            })
        })
    }

    const isFavouritePrompt = () => favouriteId !== undefined

    return <div onClick={isFavouritePrompt() ? removeFavouritePromptHandler : markAsFavouritePromptHandler}>
        {isFavouritePrompt() ? <StarIcon className='favouriteIcon' fontSize='small'/> :
            <StarBorderIcon className='favouriteIcon' fontSize='small'/>}
    </div>
}

export default FavouriteIcon