import {useUserContext} from '../context/UserContext'
import {Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState} from 'react'
import {getChatsByUser, removeChat, storeChat, updateChat} from '../service/persistenceService'
import {Chat} from '../types/Chat'
import {ChatsContextValue} from '../context/ChatsContext'
import {usePromptCreationContext} from '../context/PromptCreationContext'
import {useErrorBoundary} from 'react-error-boundary'
import {useChatMessagesContext} from '../context/ChatMessagesContext'
import {ChatMessage} from '../types/ChatMessage'
import {AIModelID} from '../types/AiModel'
import {ChatMessageStored} from '../types/ChatMessageStored'
import {sortChatMessages} from '../utils/chatUtils'
import {PromptChatDetail} from '../types/PromptChatDetail'
import {captureException} from '@sentry/core'

export type ChatFieldName = 'categories' | 'chatName' | 'chatDescription'

export const useChats = (): ChatsContextValue => {

	const {token} = useUserContext()
	const {showBoundary} = useErrorBoundary()
	const {setUnsavedChanges, setPromptChats} = usePromptCreationContext()
	const {setChatId, setChatMessages, groupMessagesByOrder, getGroupedMessagesProcessed} = useChatMessagesContext()

	const [loading, setLoading] = useState<boolean>(true)
	const [initLoadLaunched, setInitLoadLaunched] = useState<boolean>(false)
	const [chat, setChat] = useState<Chat>()
	const [chatName, setChatName] = useState<string>('')
	const [chatDescription, setChatDescription] = useState<string>('')
	const [chatLabels, setChatLabels] = useState<string[]>([])
	const [chatsByUser, setChatsByUser] = useState<Chat[]>([])

	const setChatValueHandler = useCallback((set: Dispatch<SetStateAction<any>>, value: any, fieldName: ChatFieldName) => {
		set(value)
		if (!chat) return

		const hasUnsavedChanges = fieldName === 'categories'
			? chat.labels.some((arrayValue, index) => arrayValue !== value[index])
			: chat[fieldName] !== value
		setUnsavedChanges(hasUnsavedChanges)
	},[setUnsavedChanges, chat])

	const saveChat = useCallback(async (chat: Chat, tempPromptId: string): Promise<Chat | undefined> => {
		try {
			setLoading(true)
			const storedChat = await storeChat(token, chat, tempPromptId)
				.catch(error => {
					captureException(error)
					return undefined
				})
			setLoading(false)
			if(storedChat) {
				setChat(storedChat)
				setChatsByUser(previousChatsByUser => [...previousChatsByUser, storedChat])
				setChatId(storedChat.chatId)
				setChatMessages(storedChat.messages as ChatMessageStored[])
			}
			return storedChat
		} finally {
			setLoading(false)
		}
	}, [token, setChatId, setChatMessages])

    const editChat = useCallback(async (chatToUpdate: Chat): Promise<Chat | undefined> => {
		try {
			setLoading(true)
			const updatedChat = await updateChat(token, chatToUpdate).catch(error => {
				captureException(error)
				return undefined
			})
			if (updatedChat) {
				setLoading(false)
				setChat(updatedChat)
				setChatsByUser(previousChatsByUser => {
					const foundChatIndex = previousChatsByUser.findIndex(prevChat => prevChat.chatId === updatedChat.chatId)
					if (foundChatIndex === -1) return previousChatsByUser

					previousChatsByUser[foundChatIndex] = updatedChat
					return previousChatsByUser
				})
				const sortedMessages = sortChatMessages(updatedChat.messages as ChatMessageStored[])
				setChatMessages(sortedMessages)
				const messagesProcessed = getGroupedMessagesProcessed(groupMessagesByOrder(updatedChat.messages as ChatMessageStored[]))
				const chatDetails: PromptChatDetail[] = [{modelId: chat?.modelId ?? AIModelID.GPT_3_5, messages: messagesProcessed, compilationSummary: {summary: ''}}]
				setPromptChats(chatDetails)
			}
			return updatedChat
		} finally {
			setLoading(false)
		}
	}, [token, setChatMessages, chat?.modelId, getGroupedMessagesProcessed, groupMessagesByOrder, setPromptChats])

	const deleteChat = useCallback(async (chatId: string) => {
		await removeChat(token, chatId)
		setChatsByUser(previousChats => previousChats.filter(chat => chat.chatId !== chatId))
		setChatMessages([])
	}, [token, setChatMessages])

	const loadValuesFromChat = useCallback(() => {
		setChatName(chat?.name ?? '')
		setChatDescription(chat?.description ?? '')
		setChatLabels(chat?.labels ?? [])
	}, [chat?.name, chat?.description, chat?.labels])

	const clearChatFields = useCallback(() => {
		setChatName('')
		setChatDescription('')
		setChatLabels([])
		setChat(undefined)
		setChatId(undefined)
	}, [setChatId])

	useEffect(() => {
		if (chatsByUser.length === 0 && !initLoadLaunched && token) {
			setInitLoadLaunched(true)
			setLoading(true)
			getChatsByUser(token).then(setChatsByUser).catch(showBoundary).finally(() => setLoading(false))
		}
	}, [chatsByUser.length, initLoadLaunched, token, showBoundary])

	const getChatTemplate = useCallback((messages: ChatMessage[], modelId: AIModelID): Chat => ({
		name: chatName,
		description: chatDescription,
		labels: chatLabels,
		messages,
		modelId
	}),[chatName, chatDescription, chatLabels])

	return useMemo(() => ({
		loading,
		saveChat, editChat,
		chat, setChat,
		chatName, setChatName,
		chatDescription, setChatDescription,
		chatLabels, setChatLabels,
		chatsByUser,
		setChatValueHandler,
		clearChatFields,
		loadValuesFromChat,
		deleteChat,
		getChatTemplate
	}), [loading, saveChat, editChat, chat, setChatValueHandler, chatName, chatDescription, chatLabels, chatsByUser, setChatLabels, setChatName, setChatDescription, clearChatFields, loadValuesFromChat, deleteChat, getChatTemplate])
}
