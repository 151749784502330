import {useUserContext} from '../context/UserContext'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {useFeedbackContext} from '../context/FeedbackContext'
import {getSubscriptions} from '../service/persistenceService'
import {StripeSubscriptionsContextValue} from '../context/StripeSubscriptionsContext'
import {TrackActionEvent} from '../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import {useOrganizationContext} from '../context/OrganizationContext'
import {Subscription} from '../types/Stripe'
import {getDaysUntil} from '../utils/dateUtils'
import {findTrialSubscription, hasOrganizationValidSubscription, isOrganizationNotPaidAfterTrial} from '../utils/subscriptionUtils'
import {captureException} from '@sentry/core'

export const useStripeSubscriptions = (): StripeSubscriptionsContextValue => {
	const {token} = useUserContext()
	const {showFeedback} = useFeedbackContext()
	const {user} = useUser()
	const {organization} = useOrganizationContext()

	const [subscriptions, setSubscriptions] = useState<Subscription[]>([])
	const [isActiveSubscription, setIsActiveSubscription] = useState<boolean>(true)
	const [trialDaysLeft, setTrialDaysLeft] = useState<number>()
	const [isPausedSubscription, setIsPausedSubscription] = useState<boolean>(false)

	/* TODO Agree where to set some of the metrics for this feature
		Track how many licenses are created successfully
		Track errors when a license is not created successfully */
	const getOrganizationSubscriptions = useCallback(() => {
		getSubscriptions(token).then(subscriptions => {
			setIsPausedSubscription(false)
			setSubscriptions(subscriptions)
			setIsActiveSubscription(hasOrganizationValidSubscription(subscriptions))
			const trialSubscription = findTrialSubscription(subscriptions)
			if (trialSubscription) setTrialDaysLeft(getDaysUntil(trialSubscription.trial_end!))
			if (!hasOrganizationValidSubscription(subscriptions)) {
				if (isOrganizationNotPaidAfterTrial(subscriptions)) setIsPausedSubscription(true)
				TrackActionEvent('Subscription', user?.externalId ?? user?.id, {
					is_active: false,
					customer: organization?.name,
					action: 'validate'
				})
			}
		}).catch(error => {
			captureException(error)
			showFeedback('Error','Something went wrong to load subscriptions', 'error')
			setSubscriptions([])
		})
	}, [token, showFeedback, organization?.name, user?.id, user?.externalId])

	useEffect(() => {
		if (process.env.REACT_APP_ENVIRONMENT !== 'dev') {
			getOrganizationSubscriptions()
		}
	}, [getOrganizationSubscriptions])

	return useMemo(() => ({
		subscriptions,
		isActiveSubscription,
		trialDaysLeft,
		isTrial: trialDaysLeft !== undefined,
		isPausedSubscription
	}), [subscriptions, isActiveSubscription, trialDaysLeft, isPausedSubscription])
}