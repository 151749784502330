import {FC, useEffect, useState} from 'react'
import {useUser} from '@clerk/clerk-react'
import {Avatar, Grid, Paper, Stack, Tooltip, Typography} from '@mui/material'
import Box from '@mui/material/Box'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import {MarkdownEditor} from '../aiPromptTextarea/MarkdownEditor'
import {HoverMenuOutput} from '../hoverMenuOutput/HoverMenuOutput'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import IconButton from '@mui/material/IconButton'
import './Message.scss'
import {getHomeTabForAnalytics, TrackActionEvent} from '../../service/SegmentService'
import {useFeedbackContext} from '../../context/FeedbackContext'
import {useUserContext} from '../../context/UserContext'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import Button from '@mui/material/Button'
import {AiModelIcon} from '../icons/AiModelIcon'
import {useAiModelsContext} from '../../context/AIModelsContext'
import { AIModelID } from '../../types/AiModel'
import { ChatMessage } from '../../types/ChatMessage'
import { MessageFiles } from './MessageFiles'

type Props = {
	modelId: AIModelID
	message: ChatMessage
	isLastMessage?: boolean
	onRegenerateOutput: (regenerateFromError?: boolean) => void
}

export const Message: FC<Props> = ({
	message, 
	modelId, 
	isLastMessage,
	onRegenerateOutput, 
}) => {
	const isBot = message.sender === 'bot'
	const isOptimized = !isBot && message.optimized
	const {user} = useUser()
	const {showFeedback} = useFeedbackContext()
	const {selectedTab} = useUserContext()
	const {aiModels} = useAiModelsContext()

	const [selectedOutput, setSelectedOutput] = useState(message.regeneratedOutputs ? message.regeneratedOutputs.length + 1 : undefined)
	const [textOutput, setTextOutput] = useState(message.text)

	useEffect(() => {
		setTextOutput(message.text)
	}, [message.text])

	const getSelectedOutputHandler = (isPreviousOutput: boolean) => {
		let updatedSelectedOutput: number
		if (isPreviousOutput) {
			updatedSelectedOutput = selectedOutput ? selectedOutput - 1 : 0
		} else {
			updatedSelectedOutput = selectedOutput ? selectedOutput + 1 : 0
		}
		setSelectedOutput(updatedSelectedOutput)

		if (message.regeneratedOutputs && updatedSelectedOutput) {
			const selectedIndex = message.regeneratedOutputs.length - updatedSelectedOutput

			if (selectedIndex === -1) {
				setTextOutput((prevText) => {
					if (prevText === message.text) return prevText
					return message.text
				})
			} else if (selectedIndex >= 0 && selectedIndex < message.regeneratedOutputs.length) {
				const regeneratedOutputIndex = message.regeneratedOutputs.length - selectedIndex - 1
				setTextOutput(message.regeneratedOutputs[regeneratedOutputIndex])
			}
		}
	}

	const copyToClipboardHandler = () => {
		navigator.clipboard.writeText(textOutput)
			.then(() => showFeedback('Success', 'Text copied to clipboard', 'success', 5))
			.catch(() => showFeedback('Error', 'The text couldn\'t be copied, please try again.', 'error'))
			.finally(() => {
				TrackActionEvent('Prompt', user?.externalId ?? user?.id, {
					action: 'copy_output',
					origin: getHomeTabForAnalytics(selectedTab)
				})
			})
	}

	return <Box className={`messageWrapper ${isBot ? 'botMessage' : 'userMessage'}`} flexGrow={1}>
		<Box className={`messageContainer ${isBot ? 'botMessage' : 'userMessage'}`}>
			<>
				{isBot ? <AiModelIcon modelId={modelId} className='avatar modelIcon'/> :
					<Avatar variant='rounded' sx={{bgcolor: 'primary.main'}} src={user!.imageUrl} className={`avatar${isOptimized ? ' --optimized': ''}`}>
						{user!.firstName?.at(0) ?? ''}
					</Avatar>
				}

				<Box className='messageContent'>
					<Box className='messageHeader'>
						{isBot && <Box className='botNameContainer'>
							<Typography
								className='messageAuthorName'>{aiModels.find(aiModel => aiModel.id === modelId)?.name}</Typography>
						</Box>}
						{ isOptimized ?
							<Tooltip title={
										<>
											<Typography variant='subtitle2' className='optimizedTooltipHeader'>This is an optimized prompt</Typography>
											<Typography variant='subtitle2'>Narus has improved your prompt to generate better outputs.</Typography>
										</>}>
								<Box className='optimizedContainer'>
									<AutoFixHighIcon className='optimizedIcon'/>
									<Typography variant='button' className='optimizedText'>Optimized prompt</Typography>
								</Box>
							</Tooltip> : <></> }
					</Box>
					<Paper variant='outlined'
					       className={`message ${isBot ? 'botMessage' : 'userMessage'} ${isOptimized ? 'optimizedMessage' : ''} ${message.errorMessage ? 'errorMessageContainer' : ''}`}
					       >
						{message.errorMessage ? <Grid container>
							<Grid item xs={12} className='errorMessageWrapper'>
								<ErrorOutlineIcon/>
								<MarkdownEditor loadingText='' isLoading={false} label='message' aiText={textOutput}/>
							</Grid>
							<Grid item xs={12} className='tryAgainButtonContainer'>
								<Button variant='outlined' className='tryAgainButton' onClick={() => onRegenerateOutput(true)}>Try again</Button>
							</Grid>
						</Grid> : <MarkdownEditor loadingText='' isLoading={false} label='message' aiText={textOutput} isUser={message.sender === 'user'}/>}
						
						<MessageFiles files={message.files}/>
						<HoverMenuOutput onRegenerateOutput={onRegenerateOutput}
						                 regeneratedOutputs={message.regeneratedOutputs}
						                 onCopyOutput={copyToClipboardHandler}
						                 isBot={isBot}
						                 isLastMessage={isLastMessage}/>
					</Paper>
					<Stack flexDirection='row' justifyContent='space-between' alignItems='center'>
						{isBot && message.regeneratedOutputs?.length ?
							<Box className='regeneratedMessagesSelector'>
								<IconButton disabled={selectedOutput === 1}
								            onClick={() => getSelectedOutputHandler(true)}><ChevronLeftIcon/></IconButton>
								<Typography>{selectedOutput} / {message.regeneratedOutputs.length + 1}</Typography>
								<IconButton disabled={selectedOutput === message.regeneratedOutputs.length + 1}
								            onClick={() => getSelectedOutputHandler(false)}><ChevronRightIcon/></IconButton>
							</Box> : <></>}
					</Stack>
				</Box>
			</>
		</Box>
	</Box>
}