import {useNavigate, useParams} from 'react-router-dom'
import React, {useCallback, useMemo, useState} from 'react'
import {Box, Button, Divider, Drawer, Grid, Typography} from '@mui/material'
import {TrackActionEvent} from '../../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import {useGroupsContext} from '../../../context/GroupsContext'
import {useUsersAccessContext} from '../../../context/UsersAccessContext'
import {useStatisticsContext} from '../../../context/StatisticsContext'
import {DataGridTable} from '../DataGridTable'
import {hasProp} from '../../../utils/genericUtils'
import {useAdminNavigationContext} from '../../../context/AdminNavigationContextProvider'
import {CustomDateRangePicker} from '../CustomDateRangePicker'
import {useAiModelsContext} from '../../../context/AIModelsContext'
import {roundTwoDecimals} from '../../../helpers/NumberHelpers'
import {getUsageTableColumnDefinitions} from '../../../utils/usageTableUtils'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import PeopleIcon from '@mui/icons-material/PeopleAlt'
import AutoModeIcon from '@mui/icons-material/AutoMode'
import SettingsIcon from '@mui/icons-material/Settings'
import './GroupStatistics.scss'
import {GridActionsCellItem, GridColDef, GridRowId, GridRowParams} from '@mui/x-data-grid'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import {EnabledAiModels} from '../configuration/users/EnabledAiModels'
import Spinner from '../../spinner/Spinner'
import {useToggleDrawerContext} from '../../../context/ToggleDrawerContext'
import {UserInfo} from '../../../types/UserInfo'

export const GroupStatistics = () => {

	const {groupId} = useParams()
	const {user} = useUser()
	const adminNavigationContext = useAdminNavigationContext()
	const adminNavigate = adminNavigationContext.navigate
	const navigate = useNavigate()
	const {aiModels} = useAiModelsContext()
	const {groups, allGroupsMembers} = useGroupsContext()
	const {usersInfo} = useUsersAccessContext()
	const {usageStatistics, loading} = useStatisticsContext()
	const {displayDrawerEditUser, toggleDrawer} = useToggleDrawerContext()
	const [selectedUserInfo, setSelectedUserInfo] = useState<UserInfo>()

	const existsUserStatistics = !!usageStatistics?.userUsageStatistics.length

	const open = useCallback((userId: GridRowId, event: React.MouseEvent) => {
		setSelectedUserInfo(usersInfo.find(userInfo => userInfo.userId === userId.toString()))
		return toggleDrawer(true, 'EDIT_USER_DRAWER')(event)
	}, [toggleDrawer, usersInfo])

	const groupStatisticColumns: GridColDef[] = useMemo(() => {
		const columns = [
			{field: 'userFullName', headerName: 'Name', flex: 1},
			...getUsageTableColumnDefinitions(aiModels)
		]
		if (existsUserStatistics)
			columns.push({
				field: 'actions',
				headerName: 'Actions',
				flex: 1,
				type: 'actions',
				getActions: (params: GridRowParams) => [
					<GridActionsCellItem className='aiAccessActionItem' icon={<AutoAwesomeIcon/>} label='AI access' showInMenu
										 onClick={(event) => open(params.id, event)}/>
				]
			})
		return columns
	}, [existsUserStatistics, aiModels, open])

	const group = groups.find(hasProp('hashKey', groupId))

	const groupMembers = useMemo(() =>
		allGroupsMembers.find(hasProp('groupId', groupId))?.groupMembers
	, [allGroupsMembers, groupId])

	const totalUsersInGroups = useMemo(() =>
		allGroupsMembers
			.map(groupMember => groupMember.groupMembers.length)
			.reduce((sum, current) => sum + current, 0)
	, [allGroupsMembers])

	const groupStatistics = useMemo(() =>
		usageStatistics?.groupUsageStatistics.find(hasProp('groupId', groupId))
	, [usageStatistics, groupId])

	const gridRows = useMemo(() => 
		usageStatistics?.userUsageStatistics.filter(userUsageStatistic => groupMembers?.find(hasProp('userId', userUsageStatistic.userId))) ?? []
	, [groupMembers, usageStatistics])

	const mostUsedModels: [string,number][] = useMemo(() => Object.entries(groupStatistics?.totalPromptsByModel ?? {})
		.sort(([,a],[,b]) => b - a)
		.reduce((prev, [id, total]) => {
			if (prev.length < 3) {
				return [...prev, [aiModels.find(hasProp('id', id))?.name ?? id, total]]
			} else {
				const [first, second, last] = prev
				return [first, second, ['Others', last[1] + total]]
			}
		}, [] as ([string, number][]))
	,[aiModels, groupStatistics])

	const handleGoBack = useCallback(() => {
		TrackActionEvent('Group detail dashboard', user?.externalId ?? user?.id, {action: 'show'})
		navigate(-1)
	}, [user?.id, user?.externalId, navigate])

	const handleManageTeam = useCallback(() => {
		adminNavigate({
			tab: 'configuration',
			section: 'group',
			value: groupId ?? ''
		})
	}, [groupId, adminNavigate])

	return <Box className='GroupStatistics'>
		<Box className='panel'>
			<Box className='panelHeader'>
				<Button className='title' variant='text' startIcon={<ArrowBackIcon/>} onClick={handleGoBack}>{group?.name ?? 'Without group'} usage</Button>
				<Box className='settings'>
					<Button variant='outlined' startIcon={<SettingsIcon/>} onClick={handleManageTeam}>Manage team</Button>
					<Divider orientation='vertical' flexItem/>
					<CustomDateRangePicker currentTab='OVERVIEW'/>
				</Box>
			</Box>
			<Box className='cards'>
				<Box className='members'>
					<Box className='icon'>
						<PeopleIcon/>
					</Box>
					<Box flexDirection='column'>
						<Typography className='title'>Team Members</Typography>
						<Typography className='subtitle'>{groupMembers?.length ?? usersInfo.length - totalUsersInGroups}</Typography>
					</Box>
				</Box>
				<Box className='prompts'>
					<Box className='icon'>
						<AutoModeIcon/>
					</Box>
					<Box flexDirection='column'>
						<Typography className='title'>Total Prompts</Typography>
						<Typography className='subtitle'>{groupStatistics?.totalPrompts}</Typography>
					</Box>
					<Box flexDirection='column' flexGrow={1}>
						{mostUsedModels.map(([name, total], index) =>
							<Box key={`${name}`} className='promptEntry'>
								<Box className='title'>
									<Box className={`icon${index}`} />
									<Typography className='data'>{name}</Typography>
								<Typography className='data'>{total}</Typography>
								</Box>
							</Box>)}
					</Box>
				</Box>
				<Box className='expenses'>
					<Box flexDirection='column'>
						<Typography className='title'>Total expenses</Typography>
						<Typography className='subtitle'>$ {roundTwoDecimals(groupStatistics?.totalCost ?? 0)}</Typography>
					</Box>
				</Box>
			</Box>
			<Grid xs={12} item className='dataGrid'>
				<DataGridTable
					columns={groupStatisticColumns}
					rows={gridRows}
					loading={loading}
					analyticEventName='Usage table'
					currentTable='USER_USAGE'
				/>
			</Grid>
		</Box>
		<Drawer anchor='right' open={displayDrawerEditUser}
				onClose={toggleDrawer(false, 'EDIT_USER_DRAWER')}>
			{selectedUserInfo ? <EnabledAiModels userInfo={selectedUserInfo}/> : <Spinner/>}
		</Drawer>
	</Box>
}