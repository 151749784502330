import {Box, Typography} from '@mui/material'
import {PromptsCards} from '../promptsCards/PromptsCards'
import {usePromptsContext} from '../../context/PromptsContext'
import {ReactNode} from 'react'
import './homeTabs.scss'

interface TabPanelProps {
	children?: ReactNode
	index: number
	value: number
}

export const CustomTabPanel = (props: TabPanelProps) => {
	const {children, value, index, ...other} = props

	return <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`}
	            className='tabPanelContainer'
	            aria-labelledby={`tab-${index}`}{...other}>
		{value === index && children}
	</div>
}

export const HomeTabs = () => {
	const {favourites} = usePromptsContext()

	return <Box className='favouritesContainer'>
		<Typography variant='h4'>Favourites</Typography>
		<PromptsCards library='Favourites' prompts={favourites} showLoadMore={false} noPromptsMessage='There are no favourite prompts yet'/>
	</Box>
}