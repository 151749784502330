import {PromptsContextProvider} from '../../context/PromptsContext'
import {SearchContextProvider} from '../../context/SearchContext'
import {PromptCreationContextProvider} from '../../context/PromptCreationContext'
import {ToggleDrawerContextProvider} from '../../context/ToggleDrawerContext'
import {AiModelsContextProvider} from '../../context/AIModelsContext'
import {ChatsContextProvider} from '../../context/ChatsContext'
import {ChatMessagesContextProvider} from '../../context/ChatMessagesContext'
import {Outlet} from 'react-router-dom'
import {TrialBoundary} from '../trial/TrialBoundary'
import {UserInfoContextProvider} from '../../context/UserInfoContext'
import {UserOrganizationContextProvider} from '../../context/UserOrganizationContext'
import {FeedbackContextProvider} from '../../context/FeedbackContext'
import {StripeSubscriptionsContextProvider} from '../../context/StripeSubscriptionsContext'
import {UserGroupContextProvider} from '../../context/UserGroupContext'
import {UserBudgetContextProvider} from '../../context/UserBudgetContext'
import {AppGridWrapper} from '../appGridWrapper/AppGridWrapper'
import {AppMenuWrapper} from '../appMenu/AppMenuWrapper'
import {AppNavigationContextProvider} from '../../context/AppNavigationContext'
import {UserAlerts} from '../alert/UserAlerts'
import {FilesContextProvider} from '../../context/FilesContext'

export const HomeWrapper = () => {
    return (
        <UserOrganizationContextProvider>
            <FeedbackContextProvider>
                <FilesContextProvider>
                    <PromptsContextProvider>
                        <UserInfoContextProvider>
                            <UserGroupContextProvider>
                                <StripeSubscriptionsContextProvider>
                                    <AiModelsContextProvider>
                                        <SearchContextProvider>
                                            <ChatMessagesContextProvider>
                                                <PromptCreationContextProvider>
                                                    <ChatsContextProvider>
                                                        <ToggleDrawerContextProvider>
                                                            <UserBudgetContextProvider>
                                                                <AppNavigationContextProvider>
                                                                    <AppMenuWrapper>
                                                                        <UserAlerts>
                                                                            <AppGridWrapper>
                                                                                <TrialBoundary>
                                                                                    <Outlet/>
                                                                                </TrialBoundary>
                                                                            </AppGridWrapper>
                                                                        </UserAlerts>
                                                                    </AppMenuWrapper>
                                                                </AppNavigationContextProvider>
                                                            </UserBudgetContextProvider>
                                                        </ToggleDrawerContextProvider>
                                                    </ChatsContextProvider>
                                                </PromptCreationContextProvider>
                                            </ChatMessagesContextProvider>
                                        </SearchContextProvider>
                                    </AiModelsContextProvider>
                                </StripeSubscriptionsContextProvider>
                            </UserGroupContextProvider>
                        </UserInfoContextProvider>
                    </PromptsContextProvider>
                </FilesContextProvider>
            </FeedbackContextProvider>
        </UserOrganizationContextProvider>
    )
}