import {FC, useCallback, useMemo} from 'react'
import {Box, Drawer, Typography} from '@mui/material'
import {useStatisticsContext} from '../../../context/StatisticsContext'
import {DataGridTable} from '../DataGridTable'
import {GridActionsCellItem, GridColDef} from '@mui/x-data-grid'
import './UsageTable.scss'
import {ALL_AI_MODEL_IDS} from '../../../types/AiModel'
import {not} from '../../../utils/genericUtils'
import {isModelDisabledGlobally} from '../../../utils/aiModelUtils'
import VisibilityIcon from '@mui/icons-material/Visibility'
import {TrackActionEvent} from '../../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import {useNavigate} from 'react-router-dom'
import {useAiModelsContext} from '../../../context/AIModelsContext'
import {getUsageTableColumnDefinitions} from '../../../utils/usageTableUtils'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import Spinner from '../../spinner/Spinner'
import {useToggleDrawerContext} from '../../../context/ToggleDrawerContext'
import {useGroupsContext} from '../../../context/GroupsContext'
import {EnabledAiModelsByGroup} from '../configuration/groups/EnabledAiModelsByGroup'

export const UsageTable: FC = () => {

	const {user} = useUser()
	const {usageStatistics, loading} = useStatisticsContext()
	const {aiModels} = useAiModelsContext()
	const navigate = useNavigate()
	const {displayDrawerEditGroupAiModels, toggleDrawer} = useToggleDrawerContext()
	const {
		groups,
		selectedGroup,
		setSelectedGroup
	} = useGroupsContext()

	const existsGroupStatistics = !!usageStatistics?.groupUsageStatistics?.length

	const showGroupDetail = useCallback((groupId) => {
		TrackActionEvent('Group detail dashboard', user?.externalId ?? user?.id, {action: 'show'})
		navigate(`group/${groupId}`)
	}, [user?.id, user?.externalId, navigate])

	const showEnabledModelsByGroup = useCallback((event, groupId) => {
		setSelectedGroup(groups.find(group => group.hashKey === groupId))
		return toggleDrawer(true, 'EDIT_GROUP_AI_MODELS_DRAWER')(event)
	}, [groups, setSelectedGroup, toggleDrawer])

	const columns: GridColDef[] = useMemo(() => {
		const columns = [
			{field: existsGroupStatistics ? 'groupName' : 'userFullName', headerName: 'Name', flex: 1},
			...getUsageTableColumnDefinitions(aiModels)
		]
		if (existsGroupStatistics)
			columns.push({
				field: 'actions', headerName: 'Actions', flex: 1, type: 'actions', getActions: (params) => [
					<GridActionsCellItem icon={<VisibilityIcon/>} label='Details' showInMenu onClick={() => showGroupDetail(params.id)}/>,
					<GridActionsCellItem icon={<AutoAwesomeIcon/>} label='AI Access' showInMenu disabled={groups.find(group => group.hashKey === params.id) === undefined} onClick={(event) => showEnabledModelsByGroup(event, params.id)}/>,
				]
			})
		return columns
	}, [existsGroupStatistics, aiModels, showGroupDetail, groups, showEnabledModelsByGroup])

	const columnVisibility = useMemo(() => {
		if (!usageStatistics) return {
			[existsGroupStatistics ? 'groupName' : 'userFullName']: true,
			totalPrompts: true,
			totalCost: true
		}
		const statistics = existsGroupStatistics ? usageStatistics?.groupUsageStatistics : usageStatistics?.userUsageStatistics
		return ALL_AI_MODEL_IDS
			.filter(not(isModelDisabledGlobally))
			.filter(aiModelId => !statistics?.some(statistic => statistic.totalPromptsByModel[aiModelId] > 0))
			.reduce((previousValue, aiModelId) => ({...previousValue, [aiModelId]: false}), {})
	}, [existsGroupStatistics, usageStatistics])

	return <Box className='usageTableContainer'>
		<Typography className='usageTableTitle' variant='h3'>{existsGroupStatistics ? 'Usage per teams' : 'User interactions'}</Typography>
		<DataGridTable
			columns={columns}
			rows={(existsGroupStatistics ? usageStatistics?.groupUsageStatistics : usageStatistics?.userUsageStatistics) ?? []}
			loading={loading}
			analyticEventName='Usage table'
			currentTable='USER_USAGE'
			columnVisibilityModel={columnVisibility}
		/>
		<Drawer anchor='right' open={displayDrawerEditGroupAiModels}
		        onClose={toggleDrawer(false, 'EDIT_GROUP_AI_MODELS_DRAWER')}>
			{selectedGroup ? <EnabledAiModelsByGroup group={selectedGroup}/> : <Spinner/>}
		</Drawer>
	</Box>
}